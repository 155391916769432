function UserSubscriptionsFreeTrials () {
  function ToggleFields () {
    function checkUserSegmentFields (selectedType) {
      if (selectedType === 'custom') {
        $('.custom').removeClass('hide')
        $('.ruleless').addClass('hide')
        $('#user_subscriptions_free_trial_segment_id').val('')
      } else if (selectedType === 'ruleless') {
        $('.ruleless').removeClass('hide')
        $('.custom').addClass('hide')
        $('#user_subscriptions_free_trial_orders_amount').val('')
        $('#user_subscriptions_free_trial_duration').val('')
      } else {
        $('.ruleless').addClass('hide')
        $('.custom').addClass('hide')
        $('#user_subscriptions_free_trial_orders_amount').val('')
        $('#user_subscriptions_free_trial_duration').val('')
        $('#user_subscriptions_free_trial_segment_id').val('')
      }
    }

    function checkPricingModelFields (selectedType) {
      if (selectedType === 'fixed_amount') {
        $('.fixed_amount').removeClass('hide')
        $('.percentage_discount').addClass('hide')
        $('#user_subscriptions_free_trial_percentage_discount').val('')
      } else if (selectedType === 'percentage_discount') {
        $('.percentage_discount').removeClass('hide')
        $('.fixed_amount').addClass('hide')
        $('#user_subscriptions_free_trial_fixed_amount').val('')
      } else {
        $('.fixed_amount').addClass('hide')
        $('.percentage_discount').addClass('hide')
        $('#user_subscriptions_free_trial_percentage_discount').val('')
        $('#user_subscriptions_free_trial_fixed_amount').val('')
      }
    }

    $(document).on('change', '#user_subscriptions_free_trial_pricing_model', function () {
      const selectedType = $(this).val()
      checkPricingModelFields(selectedType)
    })

    $(document).on('change', '#user_subscriptions_free_trial_user_segment', function () {
      const selectedType = $(this).val()
      checkUserSegmentFields(selectedType)
    })

    checkUserSegmentFields($('#user_subscriptions_free_trial_user_segment').val())
    checkPricingModelFields($('#user_subscriptions_free_trial_pricing_model').val())
  }

  if (window.location.pathname.includes('user_subscriptions/free_trials')) {
    ToggleFields()
  }
}

export default UserSubscriptionsFreeTrials
